import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, connect} from "react-redux";

import {Tabs, Select, Input, Form, Button, Empty, Modal, message} from "antd"


import TreeViewScriptsEndpoint from "./TreeViewScriptsEndpoint";



import {getClient} from "./util/redux/asyncActions";

import {addScriptEndpoint,removeScriptEndpoint} from "./util/redux";
import {AuthContext} from "./Auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import ManyosSpinner from "./ManyosSpinner/ManyosSpinner";



const {confirm} = Modal;

const {TabPane} = Tabs;
const {Option} = Select;

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }


const select = (state) => {
    return {
        mapping: state.request.mapping,
        type: state.request.type,
        loading: state.request.clientConfLoading,
        clientConf: state.request.clientConf,
        cmdbForms:state.request.cmdbForms,
        activeClient: state.request.activeClient,
        clientHooks:state.request.clientHooks,
        formFields:state.request.formFields,
        customForms:state.request.customForms,
        mappingCustom:state.request.mappingCustom
    }
}


const CustomForms = (props) => {
    const {mapping, type, module, loading, clientConf, activeClient, codeView,cmdbForms,clientHooks,formFields,customForms,mappingCustom} = props;
    const dispatch = useDispatch();
    const userManager = useContext(AuthContext);
    const navigate=useNavigate();


    const [scriptEndpoints, setScriptEndpoints] = React.useState(
        []
    );
    useEffect(() => {

    }, [])
    useEffect(() => {

        if (Object.keys(clientConf).length === 0) {
            dispatch(getClient({clientId: activeClient,userManager,navigate}));
        }

    }, [])


    useEffect(() => {

        let tabs = []
        if (clientConf && clientConf.hasOwnProperty("config") && clientConf.config.scriptEndpoints)  {
            tabs = Object.keys(clientConf.config.scriptEndpoints).map(config => {


                return {

                    [config]: {
                        "name": config,
                        "tabs": [
                            {
                                "name": "Scripts",
                                "table": "TreeViewScriptsEndpoint",
                                "config": config,
                                "scripts": clientConf.config.scriptEndpoints[config].scripts||[],

                            },
                        ]}


                }
            })

        }

        if (JSON.stringify(tabs)!==JSON.stringify(scriptEndpoints)){
            setScriptEndpoints(tabs);
        }




    }, [clientConf])








    const onEdit = (targetKey, action) => {

        let newEndpoint;

        function callback(e) {


            newEndpoint = e.target.value;
        }




        if (action == "add" ) {
            confirm({
                title: "Endpoint Name",
                content: <Input

                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="Name"
                    optionFilterProp="children"
                    onChange={callback}
                >

                </Input>,
                onOk: () => {
                    if (newEndpoint && newEndpoint.length > 0) {
                        dispatch(addScriptEndpoint({name:newEndpoint}));

                    } else {
                        message.error("Please enter a name!");
                    }
                }
            })
        }



        if (action==="remove" ){
            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this Endpoint?",
                onOk: () => {
                    dispatch(removeScriptEndpoint({name:targetKey}));
                },
                okText:"Yes",
                cancelText:"No"
            })

        }



    }




    const [fields, setFields] = React.useState();














    return (
        (loading)
            ? (<ManyosSpinner/>)
            : (
                <Tabs
                    className={"sub-tabs-right"}
                    tabPosition={'top'}
                    type={ "editable-card" }
                    defaultActiveKey={0}
                    onEdit={onEdit}
                    style={{height: '100%', width: '100%', backgroundColor: "white"}}

                >
                    {
                        scriptEndpoints && scriptEndpoints.map((tab,k)=>{

                            return Object.keys(tab).map((e,i)=>{

                            return(
                                <TabPane
                                    closable={true}
                                    tab={e}
                                    key={e}
                                    forceRender={true}
                                >
                                    <Tabs
                                        className={"sub-tabs-right"}
                                        tabPosition={'top'}
                                        type={ "card" }
                                        defaultActiveKey={0}
                                        forceRender={true}
                                        onEdit={onEdit}
                                        style={{height: '100%', width: '100%', backgroundColor: "white"}}

                                    >
                                        {



                                                      tab[e].tabs.map((item, i) => {

                                                        try {
                                                            let comp;

                                                            switch (item.table) {

                                                                case "TreeViewScriptsEndpoint":
                                                                    comp =  <TreeViewScriptsEndpoint {...props}  key={activeClient + "endPoints-" + item.config} tab={item.table}
                                                                                                     scripts={item.scripts} type={"scripts"} endPoint={item.config}  />
                                                                    break;

                                                                default:
                                                                    comp = <Empty/>
                                                            }
                                                            return (<TabPane
                                                                closable={false}
                                                                tab={item.name}
                                                                key={i}
                                                            >

                                                                {comp}


                                                            </TabPane>)
                                                        } catch (e) {
                                                            console.log(e)}


                                                     })

                                        }


                                    </Tabs>

                                </TabPane>
                            )})

                        })


                    }
                </Tabs>
               )
    )
}


export default connect(select)(CustomForms);
