import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";

import {Tree, message, Button,Modal,Select} from 'antd';
import {updateClientConf} from "./util/redux";


import {PlusOutlined,DeleteOutlined,EditOutlined} from '@ant-design/icons'
import {diff} from "deep-object-diff";

const {Option}=Select;
const _ = require('lodash');
const x = 3;
const y = 2;
const z = 1;
const gData = [];
const { confirm } = Modal;
const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || gData;

    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({title: key, key});
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};





const select = state => {

    let scripts;

    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("scripts") ){
        scripts=state.request.scripts.map(c=>c.id).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        scripts=state.request.scripts
    }

    return {
        darkMode: state.request.darkMode,
        activeClient: state.request.activeClient,
        clientConf: state.request.clientConf,
        rScripts:scripts,
    };
};

const TreeViewScriptsEndpoint=(props) => {
    const {scripts,clientConf,config,activeClient,rScripts}=props;

    const [tData,setTdata]=React.useState(gData);
    const [expandedKeys,setExpandedKeys]=React.useState([]);
    const [dragNodeAllowed,setDragNodeAllowed]=React.useState(false);
    const [treeData,setTreeData]=React.useState([]);


    const onDragEnter = info => {

        // expandedKeys
        setExpandedKeys(info.expandedKeys)


    };
    const loop = (data, key, callback) => {


        for (let i = 0; i < data.length; i++) {

            if (data[i].key === key ) {

                return callback(data[i], i, data);
            }
            if (data[i].children ) {

                loop(data[i].children, key, callback);
            }
        }
    };

    const onDrop = info => {

        const dropKey = info.node.key.split("|").slice(0, 2).join("|");

        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-').slice(0, 2);
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);


        const data = _.cloneDeep(tData);

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;

        });

        console.log(dragObj,"POST",dropPosition,data)
        if (!info.dropToGap) {
            // Drop on the content




            loop(data, "POST", item => {
                item.children = item.children || [];
                // where to insert ，
                item.children.unshift(dragObj);
            });

        } else {

            loop(data, "POST", item => {



                item.children = item.children || [];
                // where to insert
               // item.children.unshift(dragObj);





                const newArray = [...item.children];
                newArray.splice(dropPosition, 0, dragObj)


                item.children=newArray

            });

        }

        setTdata(data)

    };






    const dispatch=useDispatch();

    const getScriptData = () => {
        let treeData = {};



            treeData =  [{title: "POST", key: "POST", children: scripts.map(s => {
                    return {

                        key: `POST|${s}`,
                        title:s
                    }

                })
            }]





       setTdata( treeData);

    }


    React.useEffect(()=>{


        if (tData.length>0){

            let tscripts={};

            tData.forEach(methode=>{

                let methodeObj={};
                methode.children.forEach(state=>{
                    methodeObj= state.key.split("|").pop()


                })

                tscripts["Post"]=methodeObj;



            });




            function hasArrayChanged(originalArray, newArray) {
                if (originalArray.length !== newArray.length) return true; // Falls die Längen unterschiedlich sind, hat sich das Array geändert

                return originalArray.some((item, index) => {
                    const newItem = newArray[index];
                    return JSON.stringify(item) !== JSON.stringify(newItem); // Vergleich als JSON-String
                });
            }



            const newScripts=tData.map(e=>{
              return  e.children.map(c=>c.title)
            })[0]


            if (clientConf && clientConf.config && clientConf.config.scriptEndpoints &&  clientConf.config.scriptEndpoints[props.endPoint] && clientConf.config.scriptEndpoints[props.endPoint].scripts &&  hasArrayChanged(clientConf.config.scriptEndpoints[props.endPoint].scripts,newScripts)){
                console.log("changed",clientConf.config.scriptEndpoints[props.endPoint].scripts,newScripts)
                dispatch(updateClientConf({module: "scriptEndpoints", subConfig: props.endPoint, value: {scripts:newScripts}}));
            }





            setTreeData(
                tData.map(methode=>{
                            return {
                                key:methode.key,
                                title: <div ><span style={{marginRight:"10px"}}>{methode.title}</span><Button size={"small"} onClick={(e) => {
                                    e.preventDefault();
                                    let scriptName=undefined;
                                    function callback(e){
                                        scriptName=e;


                                    }

                                    confirm({
                                        content:<Select placeholder={"Scriptname"} style={{width:"300px"} } showSearch optionFilterProp="children"  filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } onSelect={callback} >
                                            {
                                                rScripts.map(script => (<Option key={script} id={script}>{script}</Option>))
                                            }
                                        </Select>,

                                        onOk:()=>{

                                            if (scriptName && scriptName.length>0){

                                                const data = _.cloneDeep(tData);
                                                loop(data, `${methode.title}`, item => {
                                                    item.children = item.children || [];

                                                    // where to insert ，
                                                    item.children.push({
                                                        key: `${methode.title}|${scriptName}`,
                                                        title:scriptName
                                                    });
                                                });

                                                setTdata(data)

                                            }else{
                                                message.error("Please enter a script name!");
                                            }
                                        }
                                        ,
                                        title:"Please enter the script name"
                                    })
                                }}
                                icon={<PlusOutlined/>}/></div>,
                                children:methode.children.map(scriptName=>{
                                    return {
                                        title:<div><span style={{marginRight:"10px"}}>{scriptName.title}</span>

                                            <Button
                                                size={"small"}
                                                onClick={()=>{
                                                    window.location.href="/scripts/"+scriptName.title;




                                                }}
                                                icon={ <EditOutlined />}
                                            />
                                            <Button
                                            size={"small"}
                                            onClick={()=>{
                                                confirm({
                                                    title:"Are you sure to delete this script?",
                                                    okText:"Yes",
                                                    cancelText:"No",
                                                    onOk:()=>{
                                                        const data = _.cloneDeep(tData);
                                                        loop(data, `POST|${scriptName.title}`, (item, index, arr) => {
                                                            arr.splice(index, 1);
                                                        });
                                                        console.log(data);
                                                        setTdata(data
                                                           );
                                                    }
                                                })



                                            }}
                                            icon={<DeleteOutlined />}
                                        /></div>,
                                        key:scriptName.key
                                    }
                                })
                            }
                    }))

        }




    },[tData,clientConf])


    React.useEffect(()=>{
       getScriptData();

    },[])




   const allowDrop = (info) => {
        const pos = info.dropNode.key.split("|").length;



        if (pos < 1) {
            return false;
        } else if ( (pos>=1 && info.dropPosition >0 ) || (pos==1 &&info.dropPosition ==0  )) {

            return true;
        } else {
            return false;
        }


    }

    const onDragStart = (info) => {

        console.log(info)
        if (info.node.pos.split("-").length < 3) {
            setDragNodeAllowed( false);
            message.error("You can not drag this element")
        } else if (info.node.props.blockDrag) {
            setDragNodeAllowed( false);
            message.error("You can not drag this element")
        } else {
            setDragNodeAllowed( true);
        }
    }






        return (
            ( treeData && treeData.length>0 )&&
            <Tree
                key={activeClient + "-" +config + "-scriptsEdit"}
                className="draggable-tree"
                draggable
                blockNode
                autoExpandParent={true}
                defaultExpandAll={true}
                allowDrop={allowDrop}
                onDragEnter={onDragEnter}
                onDragStart={onDragStart}
                onDrop={onDrop}
                treeData={treeData}

            />
        );
    }


export default connect(select,{updateClientConf})(TreeViewScriptsEndpoint)

