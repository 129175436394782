import {
    createAsyncThunk
} from "@reduxjs/toolkit";





export function smileControlAPICall({url,requestOptions,navigate}){


    if (requestOptions.hasOwnProperty("headers")){
        requestOptions.headers.Authorization =  "Basic " + btoa(window._env_.REACT_APP_SMILECONTROL_USER+":"+window._env_.REACT_APP_SMILECONTROL_PW)
    }else{
        requestOptions.headers={"Authorization":  "Basic " + btoa(window._env_.REACT_APP_SMILECONTROL_USER+":"+window._env_.REACT_APP_SMILECONTROL_PW)}
    }

    return fetch(url,requestOptions).then( data =>data.json())

}



export const getControlMetrics = createAsyncThunk("request/getControlMetrics",  async ({ navigate }) => {
    const response = await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/",requestOptions:{method:"GET",headers:{"content-type":"application/json"}},navigate});
    console.log(response)
    return response;
});
export const setControlMetrics = createAsyncThunk("request/setControlMetrics",  async ({ navigate,metric },thunk) => {

    const state = thunk.getState();
    const metricConf=state.request.newMetricConf;

    if (metric != metricConf.name){
        await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/",requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify(metricConf)},navigate});
        if (metric != "new"){
            await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/"+metric,requestOptions:{method:"DELETE",headers:{"content-type":"application/json"},body:JSON.stringify(metricConf)},navigate});
        }

    }else{
        await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/"+metricConf.name,requestOptions:{method:"PUT",headers:{"content-type":"application/json"},body:JSON.stringify(metricConf)},navigate});
    }


    const response = await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/",requestOptions:{method:"GET",headers:{"content-type":"application/json"}},navigate});
    return response;
});
export const removeMetric = createAsyncThunk("request/removeMetric",  async ({ navigate,id }) => {


            await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/"+id,requestOptions:{method:"DELETE",headers:{"content-type":"application/json"}},navigate});

    const response = await smileControlAPICall({url:window._env_.REACT_APP_SMILECONTROL_API+"/metrics/",requestOptions:{method:"GET",headers:{"content-type":"application/json"}},navigate});
    return response;
});

export function smileConnectAPIcall({ url,requestOptions,userManager,navigate }){




    return userManager.getUser().then(user=>{
            if (user){
                if (requestOptions.hasOwnProperty("headers")){
                    requestOptions.headers.Authorization =  "Bearer " + user[window._env_.REACT_APP_OIDC_TOKEN]
                }else{
                    requestOptions.headers={"Authorization":  "Bearer " + user[window._env_.REACT_APP_OIDC_TOKEN]}
                }
            }

           return fetch(url, requestOptions)
                    .then(async data => {

                            if (data.status && data.status>400){
                                let error = await data.json();
                                navigate(navigate.location.pathname, {
                                    replace:true,
                                    errorStatusCode: data.status,
                                    errorDetail: JSON.stringify({url,...error})
                                });
                            }else{

                                return data.json()
                            }

                        }).catch(error=>{

                   navigate(navigate.location.pathname, {
                       replace:true,
                       errorStatusCode: '500',
                       errorDetail: JSON.stringify({url,error:""+ error})
                   })})

                    .then(({ status, ...apiData}) => {

                        if ( ({...apiData}.hasOwnProperty("error") && {...apiData}.hasOwnProperty("stackTrace"))) {

                            const error = {...apiData};
                            navigate(navigate.location.pathname, {
                                replace:true,
                                errorStatusCode: 500,
                                errorDetail: JSON.stringify({url,...error})
                            });
                           throw Error(JSON.stringify(error))
                        } else {

                            return apiData ;
                        }
                    }).catch(error => {throw Error(error)});
        })


}


export const getClient = createAsyncThunk("request/getClient",  async ({ clientId,userManager,navigate }) => {
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/clients/"+clientId,requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

    return response;
});
export const getCmdbForms = createAsyncThunk("request/getCmdbForms",  async ( {userManager,navigate}) => {
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/forms/",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

    return response.data.filter(f=>f.indexOf("AST:")===0);
});
export const getCustomForms = createAsyncThunk("request/getCustomForms",  async ( {userManager,navigate}) => {
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/forms/",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

    return response.data;
});

export const getClients = createAsyncThunk("request/getClients",  async ({userManager,navigate}) => {

    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/clients",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

    return response;
});
export const getScripts = createAsyncThunk("request/getScripts",  async ({userManager,navigate}) => {
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/scripts",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

    return response;
});
export const getScript = createAsyncThunk("request/getScript",  async ({ scriptId,userManager,navigate }) => {
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/scripts/"+scriptId,requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});
    return response;
});

export const getClientScriptUsage = createAsyncThunk("request/getClientScriptUsage",  async ({ clientId,userManager,navigate }) => {
    const response = await smileConnectAPIcall({url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${clientId}/scriptUsage`,requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});
    return response;
});

export const getMappings = createAsyncThunk("request/getMappings",  async ({userManager,navigate}) => {

    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/mappings/fields",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});
   return response;

});

export const getCustomMappings = createAsyncThunk("request/getCustomMappings",  async ({userManager,navigate}) => {

    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/mappings/customForms",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});
    return response;

});
export const addMapping = createAsyncThunk("request/addMapping",  async ({module,mapType,values,userManager,navigate}) => {

    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/mappings/fields/"+mapType,requestOptions:{method:"PUT",headers:{"content-type":"application/json"},body:JSON.stringify({data:
        values
            })},userManager,navigate});

    return {data:response.data,mapType};

});

export const getClientHooks=createAsyncThunk("request/getClientHooks",async ({clientId,userManager,navigate})=>{
    const response = await smileConnectAPIcall({url:window._env_.REACT_APP_EVENTMGR_URL+"v1/appConfig/"+clientId,requestOptions:{method:"GET",headers:{"content-type":"application/json"}},userManager,navigate});


    return response.data;
});

export const getFormFields = createAsyncThunk("request/getFormFields",  async ({form,userManager,navigate}) => {

    if (form){
        const response = await smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/forms/"+form+"/fields",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate});

        return response;
    }else{
        return undefined
    }

});
export const removeClient = createAsyncThunk("request/removeClient",  async ({client,userManager,navigate}) => {


    if (client) {
        const response = await smileConnectAPIcall(
            {
                url: `${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${client}`,
                requestOptions:
                    {
                        method: "DELETE",
                        headers: {"content-type": "application/json"}

                    },userManager,navigate
            });
        if (window._env_.REACT_APP_EVENTMGR_URL){
            const responseEventMgr = await smileConnectAPIcall(
                {
                    url:`${window._env_.REACT_APP_EVENTMGR_URL}v1/appConfig/${client}`,
                    requestOptions:{
                        method:"DELETE",
                        headers:{"content-type":"application/json"}
                    },userManager,navigate});
        }


        return client;
    }});
export const removeScript = createAsyncThunk("request/removeScript",  async ({id,userManager,navigate}) => {


    if (id) {
        const response = await smileConnectAPIcall(
            {
                url: `${window._env_.REACT_APP_API_URL}v1/scripts/${id}`,
                requestOptions:
                    {
                        method: "DELETE",
                        headers: {"content-type": "application/json"}

                    },userManager,navigate
            });

        return response;
    }});
export const copyClient = createAsyncThunk("request/copyClient",  async ({oldClient,newClient,userManager,navigate}) => {


    if (oldClient&&newClient) {
        let responseOr = await smileConnectAPIcall(
            {
                url: `${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${oldClient}`,
                requestOptions:
                    {
                        method: "GET",
                        headers: {"content-type": "application/json"}

                    },userManager,navigate
            });

        responseOr.data.name=newClient;


        const response = await smileConnectAPIcall(
            {
                url: `${window._env_.REACT_APP_API_URL}v1/AppConfig/clients`,
                requestOptions:
                    {
                        method: "POST",
                        headers: {"content-type": "application/json"},
                        body: JSON.stringify({data:responseOr.data})
                    },userManager,navigate
            });


        return response;
    }});
export const addScript = createAsyncThunk("request/addScript",  async ({id,userManager,navigate}) => {


    if (id) {





        const response = await smileConnectAPIcall(
            {
                url: `${window._env_.REACT_APP_API_URL}v1/scripts`,
                requestOptions:
                    {
                        method: "POST",
                        headers: {"content-type": "application/json"},
                        body: JSON.stringify({id,code:""})
                    },userManager,navigate
            });


        return response;
    }});

export const saveAction = createAsyncThunk("request/saveAction",  async ({type,activeClient,scriptName,config,importType,userManager,navigate},trunk) => {



                let response={};
                if (type==="clients"){
                     response["client"] = await smileConnectAPIcall(
                        {
                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${activeClient}` ,
                            requestOptions:
                                {
                                    method:"PUT",
                                    headers:{"content-type":"application/json"},
                                    body:JSON.stringify({data:config.client})
                                },userManager,navigate
                        });

                     if (window._env_.REACT_APP_EVENTMGR_URL && window._env_.REACT_APP_EVENTMGR_URL.length>0){
                         response["events"] = await smileConnectAPIcall(
                             {
                                 url:`${window._env_.REACT_APP_EVENTMGR_URL}v1/appConfig/${activeClient}` ,
                                 requestOptions:
                                     {
                                         method:"PUT",
                                         headers:{"content-type":"application/json"},
                                         body:JSON.stringify({client:activeClient,webhooks:config.events})
                                     },userManager,navigate
                             });
                     }








                }
                if (type==="mapping"){
                    response["mapping"] = await smileConnectAPIcall(
                        {
                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/mappings/fields` ,
                            requestOptions:
                                {
                                    method:"PUT",
                                    headers:{"content-type":"application/json"},
                                    body:JSON.stringify({data:config.mapping})
                                },userManager,navigate
                        });
                    response["mappingCustom"] = await smileConnectAPIcall(
                        {
                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/mappings/customForms` ,
                            requestOptions:
                                {
                                    method:"PUT",
                                    headers:{"content-type":"application/json"},
                                    body:JSON.stringify({data:config.mappingCustom})
                                },userManager,navigate
                        });
                }
                if (type==="edit"){
                    if (importType==="mapping" && config){
                        response["mapping"] = await smileConnectAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/mappings/fields` ,
                                requestOptions:
                                    {
                                        method:"PUT",
                                        headers:{"content-type":"application/json"},
                                        body:JSON.stringify({data:config})
                                    },userManager,navigate
                            });
                    }else if (importType==="mappingCustom" && config){
                        response["mappingCustom"] = await smileConnectAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/mappings/customForms` ,
                                requestOptions:
                                    {
                                        method:"PUT",
                                        headers:{"content-type":"application/json"},
                                        body:JSON.stringify({data:config})
                                    },userManager,navigate
                            });
                    }
                    else if (importType==="clients" && config){

                        const allClients=await smileConnectAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients` ,
                                requestOptions:
                                    {
                                        method:"GET",
                                        headers:{"content-type":"application/json"},

                                    },userManager,navigate
                            });


                        let newClients=[];
                        config.forEach(client=>{
                            try{
                                if (allClients.data.filter(ac=>ac.name===client.name).length===0){
                                    //POST
                                    smileConnectAPIcall(
                                        {
                                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients` ,
                                            requestOptions:
                                                {
                                                    method:"POST",
                                                    headers:{"content-type":"application/json"},
                                                    body:JSON.stringify({data:client})
                                                },userManager,navigate
                                        }).then(r=>{newClients.push(r.data)});
                                }else{
                                    //PUT
                                    smileConnectAPIcall(
                                        {
                                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${client.name}` ,
                                            requestOptions:
                                                {
                                                    method:"PUT",
                                                    headers:{"content-type":"application/json"},
                                                    body:JSON.stringify({data:client})
                                                },userManager,navigate
                                        }).then(r=>{newClients.push(r.data)});
                                }
                            }catch(e){
                                console.log("something went wrong: "+ e)
                            }

                        });
                        allClients.data.forEach(oldClient=>{
                            console.log("old",oldClient.name)
                            try{
                                if (config.filter(nc=>nc.name===oldClient.name).length===0){
                                    //DELETE
                                    smileConnectAPIcall(
                                        {
                                            url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${oldClient.name}` ,
                                            requestOptions:
                                                {
                                                    method:"DELETE",
                                                    headers:{"content-type":"application/json"}

                                                },userManager,navigate
                                        });
                                    if (window._env_.REACT_APP_EVENTMGR_URL){
                                    smileConnectAPIcall(
                                        {
                                            url:`${window._env_.REACT_APP_EVENTMGR_URL}v1/appConfig/${oldClient.name}`,
                                            requestOptions:{
                                                method:"DELETE",
                                                headers:{"content-type":"application/json"}
                                            },userManager,navigate});
                                    }

                                }
                            }catch(e){
                                console.log("something went wrong: "+ e)
                            }

                        });
                        const newConf=await smileConnectAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients` ,
                                requestOptions:
                                    {
                                        method:"GET",
                                        headers:{"content-type":"application/json"},

                                    },userManager,navigate
                            });

                        response["clients"]=newConf.data;


                    }else if (importType==="client" && config){
                        const allClients=await smileConnectAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients` ,
                                requestOptions:
                                    {
                                        method:"GET",
                                        headers:{"content-type":"application/json"},

                                    },userManager,navigate
                            });
                        let newClients=allClients.data;

                        if (newClients.filter(ac=>ac.name===config.name).length===0){
                            //POST
                            smileConnectAPIcall(
                                {
                                    url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients` ,
                                    requestOptions:
                                        {
                                            method:"POST",
                                            headers:{"content-type":"application/json"},
                                            body:JSON.stringify({data:config})
                                        },userManager,navigate
                                }).then(r=>{newClients.push(r.data)});
                        }else{
                            //PUT
                            smileConnectAPIcall(
                                {
                                    url:`${window._env_.REACT_APP_API_URL}v1/AppConfig/clients/${config.name}` ,
                                    requestOptions:
                                        {
                                            method:"PUT",
                                            headers:{"content-type":"application/json"},
                                            body:JSON.stringify({data:config})
                                        },userManager,navigate
                                }).then(r=>{
                                    newClients.map(c=> {
                                        if (c.name===r.data.name){
                                            return r.data
                                        }
                                        return c

                                    })
                                })

                        }
                        response["clients"]=newClients;

                    }


                }
                if (type==="scripts"){
                response["scripts"] = await smileConnectAPIcall(
                    {
                        url:`${window._env_.REACT_APP_API_URL}v1/scripts/${config.id}` ,
                        requestOptions:
                            {
                                method:"PUT",
                                headers:{"content-type":"application/json"},
                                body:JSON.stringify(config)
                            },userManager,navigate
                    });









    }
                if (type==="metrics"){
        response["metrics"] = await smileControlAPICall(
            {
                url:`${window._env_.REACT_APP_API_URL}v1/scripts/${config.id}` ,
                requestOptions:
                    {
                        method:"PUT",
                        headers:{"content-type":"application/json"},
                        body:JSON.stringify(config)
                    },userManager,navigate
            });









    }
            return response;



});
