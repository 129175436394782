import React from 'react';


import {Form,Input,Switch} from 'antd';
import {updateClientOptions} from './util/redux/index';
import {useDispatch} from "react-redux";

const ClientOptions = ( props )=>{
    const dispatch=useDispatch();

    const {options}=props;
    console.log(options);
    const onValuesChange=(changedValues,allValues)=>{

            dispatch(updateClientOptions({...allValues}));
    }

    return (
        <Form
            style={{margin:"20px"}}
            name="basic"
            initialValues={options}

            onValuesChange={onValuesChange}
        >
            <Form.Item
                label="Date Format"
                name="dateFormat"
                rules={[{ required: false}]}

            >
                <Input  />
            </Form.Item>
            <Form.Item
                label="Impersonate"
                name="impersonateUser"
                rules={[{ required: false}]}
            >
                <Input  />
            </Form.Item>
            <Form.Item
                label="allow dynamic impersonate"
                name="allowDynamicImpersonate"
                valuePropName="checked"
                rules={[{ required: false}]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Translate Selection Fields"
                name="translateSelectionFields"
                valuePropName="checked"
                rules={[{ required: false}]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Link to interface documentation"
                name="documentationLink"
                rules={[{ required: false}]}
            >
                <Input  />
            </Form.Item>
            <Form.Item
                label="OpenAPI Description"
                name="openAPIDescription"

                rules={[{ required: false}]}
            >
                <Input.TextArea />
            </Form.Item>


        </Form>
    )

}


export default ClientOptions;
