import React, {useContext, useEffect, useRef, useState} from "react";
import {Table, Input, Button, Space, Form} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {sorter} from "./util/sorter";
import {useDispatch} from "react-redux";
import {current} from "@reduxjs/toolkit";


const EditableContext = React.createContext(null);



const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          FormComp,
                            mapType,
                          currentData,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);

    const form = useContext(EditableContext);
    const dispatch = useDispatch();


    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave(record,values)


        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
        childNode = //editing ? (
            <Form.Item

                style={{
                    margin: 0,
                    paddingRight: 0,
                }}
                name={dataIndex}

                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <FormComp  handlesave={save}  />
            </Form.Item>
        /*) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}

                onMouseOver={toggleEdit}

            >
                {children}
            </div>
        );*/
    }

    return <td {...restProps}>{childNode}</td>;
};

export default class ManyosTable extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
    };
    mySorter=sorter;

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#EF7721' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {

                setTimeout(() => this.searchInput.select(), 100);
            }
        },

        render: text =>
            this.state.searchedColumn === dataIndex && !(this.props.columns && this.props.columns.find(c=>c.dataIndex===dataIndex).hasOwnProperty("render")) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (

                (this.props.columns && this.props.columns.find(c=>c.dataIndex===dataIndex).hasOwnProperty("render")) ?
                    (this.props.columns.find(c=>c.dataIndex===dataIndex).render(text))   :     (text)



            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });

    };
    handleChange = (pagination, filters, sorter , extra) => {
        if (this.props.hasOwnProperty("onChange")){
            this.props.onChange(extra.currentDataSource);
        }

    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };



    render() {
        const {columns,...rest} = this.props;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        let newColumns = columns.map(c => {
            const {sorter,dataIndex,key,...restCol} = c;
            let tmpObj={...restCol,dataIndex};
            if (key) {
                tmpObj = {...tmpObj, ...this.getColumnSearchProps(c.key)}
            }
            if (sorter){
                const {compare,...otherSorterProps}=sorter;
                if (compare){
                    tmpObj = {...tmpObj, sorter: {
                            compare: (rowA, rowB) => this.mySorter[compare](rowA[dataIndex], rowB[dataIndex]),
                            ...otherSorterProps,
                        }}
                }else{
                    tmpObj = {...tmpObj, sorter: {
                            compare: (rowA, rowB) => this.mySorter["DEFAULT"](rowA[dataIndex], rowB[dataIndex]),
                            ...otherSorterProps,
                        }}
                }

            }else{
                tmpObj = {...tmpObj, sorter: {
                        compare: (rowA, rowB) => this.mySorter["DEFAULT"](rowA[dataIndex], rowB[dataIndex]),
                        multiple:1,
                    }}
            }
            return tmpObj;
        })

        newColumns = newColumns.map(col=>{

                if (!col.hasOwnProperty("extra") || (!col.extra.hasOwnProperty("editable") || !col.extra.editable)) {

                    return col;
                }

                return {
                    ...col,
                    onCell: (record) => ({
                        record,
                        editable: col.extra.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave: col.extra.handleSave,

                        FormComp: col.extra.FormComp,

                    }),
                };

        });


        return <Table columns={newColumns} components={components} onChange={this.handleChange} {...rest}/>;
    }
}


