import {Alert} from "antd";

import Table from "./ManyosTable";
import React from "react";
import {connect, } from "react-redux";



const select = (state) => {
    return {

        clientScriptUsage: state.request.clientScriptUsage,
        loading: state.request.scriptUsageLoading,
        scripts:state.request.scripts

    }
}


const TableScriptUsage = (props) => {
    const { clientScriptUsage,scripts} = props;

    console.log(scripts)
    return (
        <>

            <Table
                style={{padding: "0 15px 0 15px"}}
                pagination={false}
                dataSource={
                    clientScriptUsage && Array.isArray(clientScriptUsage) && clientScriptUsage.map((entry, i) => {

                            let check="missing";
                            if (scripts.map(s=>s.id).indexOf(entry.script)>-1){
                                check="passed"
                            }

                            return {
                                ...entry,key: i,location:entry.key,check


                            }
                        }
                    )

                }


                columns={
                    [{
                        title: 'Location',
                        dataIndex: 'location',
                        key: 'location'

                    },
                    {
                        title: 'Operation',
                        dataIndex: 'operation',
                        key: 'operation'
                    },
                    {
                        title: 'Script Type',
                        dataIndex: 'scriptType',
                        key: 'scriptType'
                    },
                    {
                        title: 'Script',
                        dataIndex: 'script',
                        key: 'script',
                        render: (text) => <a href={`/scripts/${text}`}>{text}</a>
                    },
                        {
                            title: 'Check',
                            dataIndex: 'check',
                            key: 'check',
                            render: (text) => {
                                if (text==="missing"){
                                 return   <Alert message="This script is missing" type="error" showIcon />
                                }else{
                                    return   <Alert message="Check passed" type="success" showIcon />
                                }
                                <a href={`/scripts/${text}`}>{text}</a>
                            }
                        }
                    ]
                }
            >

            </Table>
        </>)
}
export default connect(select)(TableScriptUsage);

