import React, {useContext, useEffect, useState} from 'react';


import {Dropdown, Menu, Button, PageHeader,Empty,Select,Input,Modal,message} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import { TeamOutlined,ForkOutlined,DownloadOutlined,UsergroupAddOutlined,CopyOutlined,DeleteOutlined,MenuOutlined ,CodeOutlined,PlusOutlined,FileOutlined ,MonitorOutlined} from '@ant-design/icons';

import {useDispatch,connect} from "react-redux";
import {
    getClients,
    getMappings,
    getClient,
    getClientHooks,
    saveAction,
    removeClient,
    copyClient,
    getScripts,
    getScript,
    addScript,
    removeScript,
    getControlMetrics,
    removeMetric
} from "../components/util/redux/asyncActions";
import {addMetric, dismissSave, setNavProps} from "../components/util/redux";

import {AuthContext} from "../components/Auth/AuthProvider";
import ManyosSpinner from "../components/ManyosSpinner/ManyosSpinner";
import submenu from "../config/submenu.json";

const {confirm}=Modal;
const { Option } = Select;

const { SubMenu } = Menu;
const {Search} = Input;

const select = state => {


    let clients;
    let scripts;
    let controlMetrics;

    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("clients") && state.request.clients ){
        clients=state.request.clients.map(c=>c.name).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        clients=state.request.clients;
    }
    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("scripts") && state.request.scripts){
        scripts=state.request.scripts.map(c=>c.id).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        scripts=state.request.scripts
    }
    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("controlMetrics") && state.request.controlMetrics){
        controlMetrics=state.request.controlMetrics.map(c=>c.name).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        controlMetrics=state.request.controlMetrics
    }


        return {
            clients,
            scripts,
            controlMetrics,
            allClientConfigs:state.request.clients,
            module:state.request.module,
            type:state.request.type,
            scriptName:state.request.scriptName,
            activeClient:state.request.activeClient,
            loading:state.request.clientsLoading,
            unsaved:state.request.unsaved,
            clientConf:state.request.clientConf,
            clientHooks:state.request.clientHooks,
            mapping:state.request.mapping,
            importClient:state.request.importClient,
            importClients:state.request.importClients,
            importMapping:state.request.importMapping,
            importType:state.request.importType,
            mappingCustom:state.request.mappingCustom
        };


};




const  Navigator=(props) =>{

  const dispatch=useDispatch();
  const userManager = useContext(AuthContext);
  const navigate=useNavigate();


  const {clients,loading,module,type,activeClient,unsaved,clientConf,clientHooks,mapping,importClients,importClient,importMapping,importType,scripts,scriptName,mappingCustom,controlMetrics,allClientConfigs}=props;

  useEffect(()=>{
    dispatch(getClients({userManager,navigate}))
      dispatch(getScripts({userManager,navigate}))

      if (window._env_.REACT_APP_SMILECONTROL_API){
          console.log("Load SMILEcontrol config")
          dispatch(getControlMetrics({navigate}))
      }
  },[]);




   const [searchText,setSearchText]=useState(new RegExp(/.*/,"i"));
    const [searchScriptText,setSearchScriptText]=useState(new RegExp(/.*/,"i"));
    const [searchMetricText,setSearchMetricText]=useState(new RegExp(/.*/,"i"));
    const [openKeys,setOpenKeys]=useState([]);



  const handleClientMenuClick = e =>{

  }
    const clientMenu=(props)=>{

      return(
          <Menu onClick={handleClientMenuClick}>
              <Menu.Item key="1" icon={<CopyOutlined />}
                         onClick={()=>{
                             let name;
                             function callback(e){
                                 name=e.target.value
                             }
                             confirm({
                                 title:"Enter the new clients name:",
                                 content:<Input placeholder={"Client Name"} onChange={callback}/>,

                                 onOk:()=>{
                                     if (name){
                                         dispatch(copyClient({oldClient:props.client,newClient:name,userManager,navigate}));

                                     }else{
                                         message.error("Enter a client name!")
                                     }


                                 }
                             })
                         }}
              >
                  Copy
              </Menu.Item>

              <Menu.Item key="2" icon={<FileOutlined />}
                         onClick={()=>{
                             navigate("/openapi/"+props.client)

                         }}
              >
                  OpenAPI Dokumentation

              </Menu.Item>
              {
                 props.documentationLink && (<Menu.Item key="3" icon={<FileOutlined />}
                             onClick={()=>{

                                 window.open(props.documentationLink, "_blank");

                             }}
                  >
                      Interface Dokumentation

                  </Menu.Item>)
              }
              <Menu.Item key="4" icon={<DeleteOutlined />}
                         onClick={()=>{
                             confirm({
                                 title:"Are you sure?",
                                 content:"Do you really want to delete client: " + props.client + "?",
                                 okText:"Yes",
                                 cancelText:"No",
                                 onOk:()=>{
                                     dispatch(removeClient({client:props.client,userManager,navigate}))


                                 }
                             })
                         }}
              >
                  Delete

              </Menu.Item>
          </Menu>
      )
    }




    const onSearch = val => {

      let re
        if (val && val!=""){
             re=new RegExp(".*"+val+".*","i");
        }else{
            re=new RegExp(/.*/,"i");
        }

        setSearchText(re);
    }
    const onSearchScript = val => {

        let re
        if (val && val!=""){
            re=new RegExp(".*"+val+".*","i");
        }else{
            re=new RegExp(/.*/,"i");
        }

        setSearchScriptText(re);
    }

    const onSearchMetric = val => {

        let re
        if (val && val!=""){
            re=new RegExp(".*"+val+".*","i");
        }else{
            re=new RegExp(/.*/,"i");
        }

        setSearchMetricText(re);
    }
///**/

    function checkUnsaved(evt,action,url){
      if (unsaved){
          evt.preventDefault();
          confirm({
              title:"You have not saved your Changes!",
              okText:"Save",
              cancelText:"Dismiss",
              onOk:()=>{

                  if (type=="clients"){
                      dispatch(saveAction({type,activeClient,config:{client:clientConf,events:clientHooks,userManager,navigate}}))
                  }else if (type=="mapping"){
                      dispatch(saveAction({type,config:{mapping,mappingCustom},userManager,navigate}))
                  }else if (type=="edit"){

                      dispatch(saveAction({type,importType,config:(importType==="clients")?(importClients):((importType==="client")?(importClient):(importMapping)),userManager,navigate}))
                  }
                  action();
                  navigate(url);
              },
                onCancel:()=>{
                    dispatch(dismissSave());
                    action();
                    window.location.href=url;

                }


          })
      }else{
          action();
      }
    }

if (type){
      return (
          <>
          <PageHeader avatar={ {key:"avatarLogo", shape:"square", size:80, src:window._env_.REACT_APP_GUI_URL +"manyoselement.png"}} title={"SMILEconnect"}/>
          <Menu

              style={{ width: '100%' }}
              defaultSelectedKeys={[type,activeClient,scriptName]}
              onOpenChange={(actOpenKeys)=>{
                    setOpenKeys([actOpenKeys[actOpenKeys.length-1]])
                  }}
              openKeys={openKeys}
              defaultOpenKeys={[]}
              mode="inline"
          >
            <Menu.Item key="mapping" icon={<ForkOutlined />} title="Field Mapping">
              <Link to="/mapping/incident" onClick={(e)=>{

              checkUnsaved(e,()=>{
                        dispatch(getMappings({userManager,navigate}))
                },"/mapping/incident")



              }}>
                Field Mapping
              </Link>
            </Menu.Item>
            <SubMenu key="clients" icon={<TeamOutlined />} title="Clients" popupClassName={"subMenu"} >
              {
                (loading)?(
                    <ManyosSpinner/>

                ):(

                        <>
                            {<li key="search" className="ant-dropdown-menu-item"><Search placeholder="search a client" onSearch={onSearch} onChange={(e)=>onSearch(e.target.value)} style={{ paddingLeft:5,paddingRight:5, width: '100%' }} /></li>
                            }

                            {
                        (clients && clients.length > 0) ? (
                                clients.filter(c=>c.match(searchText)).map(client => (
                                    <Menu.Item key={client}>

                                        <Link style={{maxWidth:"250px" ,display:"inline-block",overflow:"hidden"}} onClick={(e) => {
                                            checkUnsaved(e,()=>{
                                                dispatch(setNavProps({activeClient: client}))
                                                dispatch(getClient({clientId:client,userManager,navigate}))
                                                if (window._env_.REACT_APP_EVENTMGR_URL && window._env_.REACT_APP_EVENTMGR_URL.length>0){
                                                    dispatch(getClientHooks({clientId:client,userManager,navigate}))
                                                }

                                            },"/clients/" + client + "/" + ((module=="clients" || module == "mapping" || type == "edit") ? "incident" : ( submenu[type][module] ? module : "options" )))

                                        }} to={"/clients/" + client + "/" + ((module=="clients" || module == "mapping" || type == "edit") ? "incident" : ( submenu[type][module] ? module : "options" ) )}>
                                            {client}
                                        </Link>
                                        <Dropdown overlay={clientMenu({client,documentationLink:allClientConfigs.find(c=>c.name===client).config.options.documentationLink})}>
                                            <Button icon={<MenuOutlined/>} style={{
                                                left: "302px",
                                                position: "absolute",
                                                border: "none",
                                                background: "none"

                                            }}></Button>
                                        </Dropdown>
                                    </Menu.Item>
                                ))

                            ) : (<Empty></Empty>)
                        }
                        </>


                    )


              }

            </SubMenu>
              <SubMenu key="scripts" icon={<CodeOutlined  popupClassName={"subMenu"}/>}
                       title={
                           <>
                              <span >Scripts</span>
                               <Button style={{float:"right",border: "none",
                                   background: "none"}} icon={<PlusOutlined />}
                                       onClick={(e)=>{
                                           let name;
                                           function callback(e){
                                               name=e.target.value
                                           }
                                           confirm({
                                               title:"Enter the new script name:",
                                               content:<Input placeholder={"Script Name"} onChange={callback}/>,

                                               onOk:()=>{
                                                   if (name){
                                                       dispatch(addScript({id:name,userManager,navigate}));
                                                       dispatch(setNavProps({type:"scripts",scriptName: name}));
                                                       dispatch(getScript({scriptId:name,userManager,navigate}))
                                                       navigate("/scripts/"+name)

                                                   }else{
                                                       message.error("Enter a client name!")
                                                   }


                                               }
                                           })
                               }
                               }/>
                           </>}
              >
                  {
                      (loading)?(
                          <ManyosSpinner/>
                      ):(

                          <>
                              {<li key="search" className="ant-dropdown-menu-item"><Search placeholder="search a script" onSearch={onSearchScript} onChange={(e)=>onSearchScript(e.target.value)} style={{ paddingLeft:5,paddingRight:5, width: '100%' }} /></li>
                              }
                              {
                                  (scripts && scripts.length > 0) ? (
                                      scripts.filter(c=>c.match(searchScriptText)).map(script => (

                                          <Menu.Item key={script} >

                                              <Link  style={{maxWidth:"250px" ,display:"inline-block",overflow:"hidden"}} onClick={(e) => {
                                                  checkUnsaved(e,()=>{
                                                      dispatch(setNavProps({type:"scripts",scriptName: script}))
                                                      dispatch(getScript({scriptId:script,userManager,navigate}))

                                                  },"/scripts/" + script)

                                              }} to={"/scripts/" + script  }>
                                                  {script}
                                              </Link>
                                              <Button
                                                  icon={<DeleteOutlined />}
                                                  style={{
                                                      left: "302px",
                                                      position: "absolute",
                                                      border: "none",
                                                      background: "none"
                                                  }}

                                                  onClick={()=>{
                                                      confirm({
                                                          title:"Are you sure?",
                                                          content:"Do you really want to delete script: " + script + "?",
                                                          okText:"Yes",
                                                          cancelText:"No",
                                                          onOk:()=>{
                                                              dispatch(removeScript({id:script,userManager,navigate}))
                                                              navigate("/")


                                                          }
                                                      })}}
                                              >

                                              </Button>
                                          </Menu.Item>
                                      ))

                                  ) : (<Empty></Empty>)
                              }

                          </>


                      )


                  }

              </SubMenu>


              {window._env_.REACT_APP_SMILECONTROL_API &&

                  <SubMenu key="monitoring" icon={<MonitorOutlined   popupClassName={"subMenu"}/>}
                           title={
                               <>
                                   <span >Monitoring</span>
                                   <Button style={{float:"right",border: "none",
                                       background: "none"}} icon={<PlusOutlined />}
                                           onClick={(e)=>{
                                               let name;
                                               function callback(e){
                                                   name=e.target.value
                                               }
                                               confirm({
                                                   title:"Enter the new metric name:",
                                                   content:<Input placeholder={"Metric Name"} onChange={callback}/>,

                                                   onOk:()=>{

                                                       if (name && name.match(/^[a-zA-Z0-9_-]*$/)){

                                                           dispatch(addMetric({name,userManager,navigate}));
                                                           dispatch(setNavProps({type:"metrics",metricName: name}))
                                                           navigate("/metrics/"+name)

                                                       }else{
                                                           message.error("Enter a valid metric name! Input can only contain letters (a-z, A-Z), numbers (0-9), underscores (_), and hyphens (-). No spaces or special characters are allowed.")
                                                       }


                                                   }
                                               })
                                           }
                                           }/>
                               </>}
                  >
                      {
                          (loading)?(
                              <ManyosSpinner/>
                          ):(

                              <>
                                  {<li key="search" className="ant-dropdown-menu-item"><Search placeholder="search a metric" onSearch={onSearchMetric} onChange={(e)=>onSearchMetric(e.target.value)} style={{ paddingLeft:5,paddingRight:5, width: '100%' }} /></li>
                                  }

                                  {
                                      (controlMetrics && controlMetrics.length > 0) ? (
                                          controlMetrics.filter(c=>c.match(searchMetricText)).map(metric => (

                                              <Menu.Item key={metric} >

                                                  <Link  style={{maxWidth:"250px" ,display:"inline-block",overflow:"hidden"}} onClick={(e) => {
                                                      checkUnsaved(e,()=>{
                                                          dispatch(setNavProps({type:"metrics",metricName: metric}))
                                                          /*dispatch(getMetric({metric:metric,userManager,navigate}))*/

                                                      },"/metrics/" + metric)

                                                  }} to={"/metrics/" + metric  }>
                                                      {metric}
                                                  </Link>
                                                  <Button
                                                      icon={<DeleteOutlined />}
                                                      style={{
                                                          left: "302px",
                                                          position: "absolute",
                                                          border: "none",
                                                          background: "none"
                                                      }}

                                                      onClick={()=>{
                                                          confirm({
                                                              title:"Are you sure?",
                                                              content:"Do you really want to delete metric: " + metric + "?",
                                                              okText:"Yes",
                                                              cancelText:"No",
                                                              onOk:()=>{
                                                                  dispatch(removeMetric({id:metric,userManager,navigate}))
                                                                  navigate("/")


                                                              }
                                                          })}}
                                                  >

                                                  </Button>
                                              </Menu.Item>
                                          ))

                                      ) : (<Empty></Empty>)
                                  }

                              </>


                          )


                      }

                  </SubMenu>
              }
            <Menu.Item key="fileManagement" icon={<DownloadOutlined />} title="Import / Export">
                <Link to="/edit/clients" onClick={(e)=>{

                    checkUnsaved(e,()=>{dispatch(getMappings({userManager,navigate})) },"/edit/clients")}}>
                    Import / Export
                </Link>
               </Menu.Item>
            <Menu.Item key="wizard" icon={ <UsergroupAddOutlined />} title="ITSM Wizard">
              <a href={window._env_.REACT_APP_WIZZARD_URL} target="_new" rel="noopener noreferrer">
                ITSM Wizard
              </a>
            </Menu.Item>


          </Menu>
        </>

      );
    }else{ return null}
}



export default connect(select)(Navigator)
